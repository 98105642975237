var config = {
  ajaxOptions: {
    cache: false // Most browsers don't cache the response anyway but IE does
  },
  defaultLanguage: 'en',
  supportedLanguages: [
    'en',
    'id',
    'zh',
    'ja'
  ]
};
