var dcpDiscount = parseInt(Cookies.get('dcp-discount'), 10) || 0;
var staticRate = parseInt(Cookies.get('static-rate'), 10) || 0;

function validCookieData() {
  var errors = [];

  var ctyhocn = Cookies.get('ctyhocn-code');
  if(!ctyhocn) {
    errors.push($.t('admin_error_ctyhocn'));
  }

  var hotelName = Cookies.get('hotel-name');
  if(!hotelName) {
    errors.push($.t('admin_error_hotel_name'));
  }

  var dcpDiscount = Cookies.get('dcp-discount');
  if(!dcpDiscount) {
    errors.push($.t('admin_error_dcp_discount'));
  }

  var days = Cookies.get('day-selection');
  if(!days) {
    errors.push($.t('admin_error_day_selection'));
  } else {
    days = JSON.parse(days); // Object with a property for each day
    days = _.filter(_.values(days)); // Pluck key values to countable array
    if(days.length === 0) {
      errors.push($.t('admin_error_day_selection'));
    }
  }

  var roomTypes = Cookies.get('room-type-selection');
  if(!roomTypes) {
    errors.push($.t('admin_error_room_type'));
  } else {
    roomTypes = JSON.parse(roomTypes);
    if(roomTypes.length === 0) {
      errors.push($.t('admin_error_room_type'));
    }
  }

  return errors;
}

function getStaticGraphData(callback) {
  var ctyhocnCode = Cookies.get('ctyhocn-code');
  var ajaxFunctions = [];

  ajaxFunctions.push(function(cb) {
    $.getJSON('/api/rates-by-day')
      .done(function(data) {
        if(!data) {
          return cb(new Error('No Data from endpoint /api/rates-by-day'));
        }
        cb(null, data);
      })
      .fail(function() {
        cb(new Error('Fail to get response from endpoint /api/rates-by-day'));
      });
  });

  ajaxFunctions.push(function(cb) {
    $.getJSON('/api/hotels/' + ctyhocnCode + '/room-types')
      .done(function(data) {
        if(!data) {
          return cb(new Error('No Data from endpoint /api/hotels/' + ctyhocnCode + '/room-types'));
        }
        cb(null, data);
      })
      .fail(function() {
        cb(new Error('Fail to get response from endpoint /api/hotels/' + ctyhocnCode + '/room-types'));
      });
  });

  async.parallel(ajaxFunctions, function(err, results) {
    if(err) {
      return callback(err);
    }
    var data = results[0];
    var roomList = results[1];
    callback(null, data, roomList);
  });
}

function getRatesData(callback) {
  $.getJSON('/api/rates-by-day')
    .done(function(data) {
      if(!data || data.error) {
        return callback(new Error('No Data from endpoint /api/rates-by-day'));
      }
      callback(null, data);
    })
    .fail(function() {
      callback(new Error('Fail to get response from endpoint /api/rates-by-day'));
    });
}

function splitDataToLines(data) {
  var lines = [];
  var buffer = [];
  for(var i = 0; i < data.length; i++) {
    var currentDay = data[i];
    // If there is availability add to buffer
    if(currentDay.roomAvailable) {
      currentDay.dataIndex = i;
      buffer.push(currentDay);
    }
    if(!currentDay.roomAvailable && buffer.length > 0) {
      lines.push(buffer);
      buffer = [];
    }
  }
  if(buffer.length > 0) {
    lines.push(buffer);
  }
  return lines;
}

function drawStaticValue(svg, graphWidth, yPosition) {
  // Draw static price
  var hideStatic = false;
  var hideStaticCookie = Cookies.get('hide-static-rate-value');
  if(hideStaticCookie) {
    hideStatic = JSON.parse(hideStaticCookie);
  }
  if(!hideStatic) {
    var currencyCode = Cookies.get('currency-shortcode') || '';
    currencyCode = htmlDecode(currencyCode);
    var staticValue = staticRate + ' ' + currencyCode;
    var leftPadding = 10;
    svg.append('g')
      .attr('class', 'static-price-label')
      .append('text')
      .attr('x', graphWidth + leftPadding)
      .attr('y', yPosition)
      .attr('dy', '0.25em')
      .text(staticValue);
  }
}

function setDataRange(data) {
  if(data) {
    var dateFormat = 'DD MMM YYYY';
    if ($.i18n.language === 'ja') {
      dateFormat = 'YYYY年MM月DD日';
    }
    if ($.i18n.language === 'zh') {
      dateFormat = 'DD/MM/YYYY';
    }
    var start = new Date(data[0].date);
    start = moment(start).format(dateFormat);
    var end = new Date(data[data.length - 1].date);
    end = moment(end).format(dateFormat);
    var range = $.t('dynamic_graph_stats_range_dates', { startdate: start, enddate: end});
    $('.data-selection-box.dates .text').html(range);
  }
}

function calculateDcpDiscount(day) {
  var barPrice = parseInt(day.amountBeforeTax, 10);
  return ((100 - dcpDiscount) / 100) * barPrice;
}

function calculateDcpSavingDays(data) {
  var dcpSavings = {
    daycount: 0,
    saving: 0
  };
  data.forEach(function(day) {
    // Best DCP rate
    if(day.roomAvailable) {
      var dcp = calculateDcpDiscount(day.lowestOther);
      if(dcp < staticRate) {
        dcpSavings.daycount++;
        dcpSavings.saving += staticRate - dcp;
      }
    }
  });
  return dcpSavings;
}

function populateCallouts(data) {

  var $outOfDays = $('.out-of-days');
  if($outOfDays.length) {
    var daysText = $.t('dynamic_graph_bubbles_days', { days: data.length });
    $outOfDays.html(daysText);
  }

  var dcpSavings = calculateDcpSavingDays(data);
  var $dcpSavingVsStatic = $('.dcp-saving-vs-static');
  if($dcpSavingVsStatic.length) {
    $dcpSavingVsStatic.html(dcpSavings.daycount);
  }

  var $monetarySaving = $('.monetary-saving');
  if($monetarySaving.length) {
    var format = '0,0';
    if(dcpSavings.saving > 1000 && $.i18n.language !== 'ja') {
      format = '0,0.0a';
    }
    var roundedSaving = numbro(dcpSavings.saving).format(format);
    $monetarySaving.html(roundedSaving);
    $monetarySaving.addClass('text-length-' + roundedSaving.length);
  }

  var $currencyShortcode = $('.currency-shortcode');
  if($currencyShortcode.length) {
    var shortcode = htmlDecode(Cookies.get('currency-shortcode'));
    $currencyShortcode.html(shortcode);
  }
}

function resizeListener(callback, data, roomList) {
  $(window).resize(function() {
    setTimeout(function() {
      $('.drawn-graph').first().remove();
      callback(data, roomList);
    }, 100);
  });
}

function callOutCookies($this) {
  var $callout = $this.closest('.call-out');
  if(!$callout.length) {
    return;
  }

  var hide = false;
  if($callout.hasClass('exclude-from-pdf')) {
    hide = true;
  }

  var callOutId = $callout.attr('id');
  Cookies.set('hide-' + callOutId, hide, cookieOptions);
}

$(document).on('click', '.hide-btn', function() {
  var $this = $(this);
  $this.closest('.call-out').toggleClass('exclude-from-pdf');
  callOutCookies($this);
});

/* Detect browser */
var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);
