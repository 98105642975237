var d3Locales = {
  en: {
    'dateTime': '%x, %X',
    'date': '%-m/%-d/%Y',
    'time': '%-I:%M:%S %p',
    'periods': [ 'AM', 'PM' ],
    'days': [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
    'shortDays': [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
    'months': [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    'shortMonths': [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  },
  ja: {
    'dateTime': '%x %a %X',
    'date': '%Y/%m/%d',
    'time': '%H:%M:%S',
    'periods': [ 'AM', 'PM' ],
    'days': [ '日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日' ],
    'shortDays': [ '日', '月', '火', '水', '木', '金', '土' ],
    'months': [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
    'shortMonths': [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ]
  },
  id: {
    'dateTime': '%x, %X',
    'date': '%-m/%-d/%Y',
    'time': '%-I:%M:%S %p',
    'periods': [ 'AM', 'PM' ],
    'days': [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
    'shortDays': [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
    'months': [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    'shortMonths': [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  },
  zh: {
    'dateTime': '%x %A %X',
    'date': '%Y年%-m月%-d日',
    'time': '%H:%M:%S',
    'periods': [ '上午', '下午' ],
    'days': [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ],
    'shortDays': [ '周日', '周一', '周二', '周三', '周四', '周五', '周六' ],
    'months': [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
    'shortMonths': [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ]
  }
};
