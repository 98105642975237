function getCookie(name) {
  var cookieName = name + '=';
  var cookies = document.cookie;

  // Find the cookie in cookies
  var start = cookies.indexOf('; ' + cookieName);
  if(start === -1) {
    start = cookies.indexOf(cookieName);
    if(start !== 0) {
      // If we can't find it return null
      return null;
    }
  }
  // If we can find it add the length of the name to get the start of the data
  start = start + cookieName.length;

  // Find the end of the cookie
  var end = cookies.indexOf(';', start);
  if(end === -1) {
    end = cookies.length;
  }

  // Return the cookie data
  return unescape(cookies.substring(start, end));
}

function htmlEncode(str) {
  var buf = [];

  for (var i=str.length-1; i>=0; i--) {
    buf.unshift([ '&#', str[i].charCodeAt(), ';' ].join(''));
  }

  return buf.join('');
}

function htmlDecode(str) {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
}

$.ajaxSetup(config.ajaxOptions);

var lng = config.defaultLanguage;
var cookieLng = getCookie('i18next');
if(cookieLng !== null) {
  // Remove t= so we just get the language code
  cookieLng = cookieLng.replace('t=', '');
  if(config.supportedLanguages.indexOf(cookieLng) !== -1) {
    lng = cookieLng;
  }
}

// use plugins and options as needed, for options, detail see
// http://i18next.com/docs/
$.t = function() {};
i18next.use(i18nextXHRBackend).init({
  lng, // evtl. use language-detector https://github.com/i18next/i18next-browser-languageDetector
  backend: {
    loadPath: '/locales/{{lng}}.json'
  }
}, function() {
  jqueryI18next.init(i18next, $);

  var $langForm = $('#language-form');
  var $langCode = $('#language-code');
  $langCode.find('#' + i18next.language).attr('selected', true);
  if($langForm.length) {
    $langCode.change(function() {
      var cookie = 'i18next=' + $langCode.val() + ';secure=true; path=/';
      document.cookie = cookie;
      window.location.reload();
    });
  }

  moment.locale($.i18n.language);

  // 401 page
  if($('.page-401').length) {
    $('body').removeClass('loading');
  }

  // Dynamic Graph Page Init
  if($('.page-dynamic-graph').length) {
    var validationErrors = validCookieData();
    if(!validationErrors.length) {
      initDynamicGraph();
    }
  }

  // Admin Page Init
  if($('.page-admin').length) {
    updateHotels();

    var selectedCode = Cookies.get('ctyhocn-code');
    if(selectedCode) {
      updateRoomTypes(selectedCode, false);
    }

    validateMinimumInfo();

    // Debounce all keyup and change events to prevent repeated calls when typing or quickly selecting multiple checkboxes
    // This might not cause issues on a dev box but remember that the server is much slower so we want to minimise calls
    var debounceDelay = 500;
    $(document).on('change', '#CTYHOCN-code', _.debounce(storeHotelSelection, debounceDelay));
    $(document).on('change', '#room-type-selection .room-type', _.debounce(storeRoomTypeSelection, debounceDelay));
    $(document).on('change', '#day-selection input[type=checkbox]', _.debounce(storeDaySelection, debounceDelay));
    $(document).on('change', '#hide-static', _.debounce(storeHideStaticRate, debounceDelay));
    $(document).on('keyup change', '#hotel-name', _.debounce(storeHotelName, debounceDelay));
    $(document).on('keyup change', '#static-rate', _.debounce(storeStaticRate, debounceDelay));
    $(document).on('keyup change', '#dcp-discount', _.debounce(storeDcpDiscount, debounceDelay));
    $(document).on('keyup change', '#currency-shortcode', _.debounce(storeCurrencyShortcode, debounceDelay));
    $(document).on('click', '#generate-pdf', generatePDF);
  }
});
