/* Store values
*****************************************************************************/

function storeRoomTypeSelection() {
  var selectedRoomTypes = [];
  $('#room-type-selection .room-type:checked').each(function() {
    selectedRoomTypes.push(this.value);
  });
  Cookies.set('room-type-selection', selectedRoomTypes, cookieOptions);
  validateMinimumInfo();
}

function storeDaySelection() {
  var daySelection = Cookies.getJSON('day-selection') || {};
  $('#day-selection input[type="checkbox"]').each(function() {
    var id = this.id;
    if(id) {
      daySelection[id] = this.checked;
    }
  });
  Cookies.set('day-selection', daySelection, cookieOptions);
  validateMinimumInfo();
}

function storeHotelSelection() {
  var ctyhocnCode = this.value;
  if(ctyhocnCode) {
    Cookies.set('ctyhocn-code', ctyhocnCode, cookieOptions);
  }

  // Clear out any room type selections and update the list of available room types
  Cookies.set('room-type-selection', [], cookieOptions);
  initDaysSelections();
  updateRoomTypes(ctyhocnCode, true);
  validateMinimumInfo();
}

function storeCurrencyShortcode() {
  var currencyShortcode = this.value.substring(0, 3) || '';
  if(currencyShortcode) {
    Cookies.set('currency-shortcode', htmlEncode(currencyShortcode), cookieOptions);
  } else {
    Cookies.remove('currency-shortcode', cookieOptions);
  }
}

function storeHotelName() {
  var hotelName = this.value;
  if(hotelName) {
    Cookies.set('hotel-name', hotelName, cookieOptions);
  } else {
    Cookies.remove('hotel-name', cookieOptions);
  }
  validateMinimumInfo();
}

function storeStaticRate() {
  var staticRate = this.value;
  if(staticRate) {
    Cookies.set('static-rate', staticRate, cookieOptions);
  } else {
    Cookies.remove('static-rate', cookieOptions);
  }
}

function storeDcpDiscount() {
  var dcpDiscount = this.value;
  if(dcpDiscount) {
    Cookies.set('dcp-discount', dcpDiscount, cookieOptions);
  } else {
    Cookies.remove('dcp-discount', cookieOptions);
  }
  validateMinimumInfo();
}

function storeHideStaticRate() {
  if(this.id) {
    Cookies.set('hide-static-rate-value', this.checked, cookieOptions);
  } else {
    Cookies.remove('hide-static-rate-value', cookieOptions);
  }
}

/* Update values / info
*****************************************************************************/

function updateDaysTotal() {
  var $daysTotal = $('.days-total');
  var cookieSnapshot = getCookieDaysSnapshot();
  getRatesData(function(err, data) {
    if(err) {
      return;
    }
    var currentCookieSnapshot = getCookieDaysSnapshot();
    // Because this is asynchronous we need to check that the relevent cookie data is the same as when
    // we made the call before updating our days total otherwise the number might not be accurate.
    // This is an issue when people tick / untick boxes quickly.
    if(_.isEqual(cookieSnapshot, currentCookieSnapshot)) {
      var daysText = $.t('admin_days_count', { days: data.length });
      $daysTotal.html(daysText);
    }
  });
}

function clearDaysTotal() {
  $('.days-total').html('');
}

function getRoomName(roomType) {
  if(roomType.roomTypeName !== '') {
    return roomType.roomType.toUpperCase() + ' (' + roomType.roomTypeName.toLowerCase() + ')';
  }
  return roomType.roomType.toUpperCase();
}

function updateRoomTypes(ctyhocnCode, defaultSelections) {
  $.getJSON('/api/hotels/' + ctyhocnCode + '/room-types')
    .then(function(roomTypes) {
      if(!roomTypes) {
        return;
      }

      roomTypes = _.sortBy(roomTypes, function(roomType) {
        return roomType.roomTypeName;
      });

      var savedSelection = Cookies.getJSON('room-type-selection') || [];
      var selectionHtml = roomTypes.map(function(roomType, index) {
        var checked = '';
        if(savedSelection.indexOf(roomType.roomType) !== -1) {
          checked = ' checked="checked"';
        }

        var html = '<div class="input-group"><label for="' + roomType.roomType + '-' + index + '">';
        html += '<input id="' + roomType.roomType + '-' + index + '" class="room-type"';
        html += ' type="checkbox" value="' + roomType.roomType + '"' + checked + '>';
        html += ' ' + getRoomName(roomType) + '</label></div>';
        return html;
      });

      $('#room-type-selection').html(selectionHtml.join(''));

      if(defaultSelections) {
        storeRoomTypeSelection();
      }
    });
}

function updateHotels() {
  $.getJSON('/api/hotels')
    .then(function(hotelIds) {
      if(!hotelIds) {
        return;
      }
      hotelIds = hotelIds.sort();
      var selectedCode = Cookies.get('ctyhocn-code');
      var hotelHtml = hotelIds.map(function(hotelId) {
        if(hotelId === selectedCode) {
          return '<option selected="selected" value="' + hotelId + '">' + hotelId + '</option>';
        }

        return '<option value="' + hotelId + '">' + hotelId + '</option>';
      });
      $('#CTYHOCN-code').append(hotelHtml.join(''));
      $('body').removeClass('loading');
      validateMinimumInfo();
    });
}

/* Other
*****************************************************************************/

function getCookieDaysSnapshot() {
  // Create a snapshot of the data that affects the number of days available
  return {
    hotel: Cookies.get('hotel-name'),
    days: Cookies.get('day-selection'),
    includeRoom: Cookies.get('room-type-selection')
  };
}

function initDaysSelections() {
  $('#day-selection input[type="checkbox"]').each(function() {
    this.checked = true;
  });
  storeDaySelection();
}

function setPdfErrorMsg(msg) {
  $('.pdf-validation-message').html(msg);
}

function generatePDF() {
  // Display validation error bar if clicked when disabled
  if($('#generate-pdf').hasClass('disabled')) {
    validateMinimumInfo('true');
    return false;
  }
  // If not disabled request pdf from backend
  var genericErrorMessage = $.t('admin_error_pdf_generic');
  var pdfMsg = '<div class="loading-spinner"><img src="/images/spinner.gif" alt="Loading..." /><div class="info">' + $.t('admin_pdf_generating') + '</div></div>';
  setPdfErrorMsg(pdfMsg);
  $('.loading-spinner').addClass('active');
  $('#generate-pdf').prop('disabled', true);
  $.getJSON('/api/validate-selections')
    .done(function(data) {
      if(!data) {
        return setPdfErrorMsg(genericErrorMessage);
      }
      if(!data.success) {
        return setPdfErrorMsg(data.msg);
      }
      var $iframe = $('iframe[src="/api/pdf"]');
      if($iframe) {
        $iframe.remove();
      }
      $.fileDownload('/api/pdf')
        .done(function() {
          setPdfErrorMsg('');
        })
        .fail(function() {
          setPdfErrorMsg($.t('admin_error_pdf_timeout'));
        })
        .always(function() {
          $('.loading-spinner').removeClass('active');
          $('#generate-pdf').prop('disabled', false);
        });
      return;
    })
    .fail(function() {
      return setPdfErrorMsg(genericErrorMessage);
    });
}

function disablePageNavigation() {
  var $navTabs = $('.main-nav .nav-tabs li:not(.active)');
  $navTabs.addClass('disabled');
  $navTabs.on('click', function() {
    validateMinimumInfo(true);
    return false;
  });
}

function enablePageNavigation() {
  var $navTabs = $('.main-nav .nav-tabs li');
  $navTabs.removeClass('disabled');
  $navTabs.off('click');
}

function validateMinimumInfo(showValidationErrors) {
  var validationErrors = validCookieData();
  var $errorBar = $('#validation-errors');
  var $pdfButton = $('#generate-pdf');
  if(validationErrors.length) {
    disablePageNavigation();
    $pdfButton.addClass('disabled');
    clearDaysTotal();
    $errorBar.html(validationErrors.join(', '));
    if(showValidationErrors) {
      $errorBar.slideDown();
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  } else {
    enablePageNavigation();
    $pdfButton.removeClass('disabled');
    updateDaysTotal();
    $errorBar.slideUp();
  }
}
