function initDynamicGraph() {
  getRatesData(function(err, data) {
    if(err) {
      return;
    }
    setDataRange(data);
    populateCallouts(data);
    drawDynamicGraph(data);
    resizeListener(drawDynamicGraph, data);
    console.log('PHANTOM: RENDER COMPLETE');
  });
}

function getStaticLines(lines) {
  var flattened = _.flattenDeep(lines);
  var data = _.filter(flattened, 'lowestIncluded');

  var staticLines = [];
  var buffer = [];
  var previousDataIndex = data[0].dataIndex;
  data.forEach(function(currentDay) {
    var currentDataIndex = currentDay.dataIndex;
    if((currentDataIndex - previousDataIndex) > 1) {
      staticLines.push(buffer);
      buffer = [];
    }
    buffer.push(currentDay);
    previousDataIndex = currentDataIndex;
  });
  if(buffer.length > 0) {
    staticLines.push(buffer);
  }

  return staticLines;
}

function drawDynamicGraph(data) {
  var w = $('.container-fluid').width();

  var lines = splitDataToLines(data);
  var roomTypes = JSON.parse(Cookies.get('room-type-selection'));
  var staticLines = [];
  if(roomTypes.length) {
    staticLines = getStaticLines(lines);
  }

  var roomAvailableData = _.filter(data, 'roomAvailable');

  var yAxisLow = _.minBy(roomAvailableData, function(day) {
    return parseInt(day.lowestOther.amountBeforeTax, 10);
  });
  yAxisLow = yAxisLow.lowestOther;

  var yAxisHigh = _.maxBy(roomAvailableData, function(day) {
    return parseInt(day.lowestOther.amountBeforeTax, 10);
  });
  yAxisHigh = yAxisHigh.lowestOther;

  var options = {
    xAxisEnabled: true,
    yAxisEnabled: true
  };

  var margin = {
    top: 20,
    right: 60,
    bottom: 30,
    left: 60
  };

  var pageMargin = {
    left: 20,
    right: 20
  };

  var width = w - margin.left - margin.right - pageMargin.left - pageMargin.right;
  var height = 350 - margin.top - margin.bottom;

  var x = d3.scale.linear()
    .range([
      0,
      width
    ])
    .domain([
      0,
      data.length - 1
    ]);

  // Using cardinal interpolation means that the lines plotted on the graph will sometimes overshoot
  // the intended range due to the added curves at the top / bottom, hence the extra padding.
  var yScaleLow = calculateDcpDiscount(yAxisLow);
  var yScaleHigh = parseInt(yAxisHigh.amountBeforeTax, 10);
  var paddingFactor = 0.15;
  var padding = yScaleHigh * paddingFactor;

  var y = d3.scale.linear()
    .range([
      height,
      0
    ])
    .domain([
      yScaleLow - padding,
      yScaleHigh + padding
    ]);

  var D3Locale = d3.locale(d3Locales[$.i18n.language]);
  var xAxis = d3.svg.axis()
    .scale(x)
    .orient('bottom')
    .tickFormat(function(i) {
      // https://d3-wiki.readthedocs.io/zh_CN/master/Time-Formatting/
      var dateFormat = '%b %d';
      if ($.i18n.language === 'ja') {
        dateFormat = '%b%d日';
      }
      if ($.i18n.language === 'zh') {
        dateFormat = '%b%d';
      }
      var inputFormat = d3.time.format.iso;
      var outputFormat = D3Locale.timeFormat(dateFormat);
      var index = Math.round(i);
      var date = inputFormat.parse(data[index].date);
      return outputFormat(date);
    });

  var yAxis = d3.svg.axis()
    .scale(y)
    .orient('left')
    .ticks(10)
    .tickSize(-width, 0)
    .tickPadding(0)
    .tickFormat(function() {
      return '';
    });

  // Draw svg
  var svg = d3.select('body .graph-container')
    .append('svg')
    .attr('class', 'drawn-graph')
    .attr('width', w)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  // Draw no availability bars
  generateNoAvailabilityBars(svg, width, height, data, x);

  // Draw X axis
  if(options.xAxisEnabled) {
    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);
  }

  // Draw Y axis
  if(options.yAxisEnabled) {
    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .selectAll('.tick text')
      .style('text-anchor', 'start');
  }

  // Define Lines
  var staticPriceLine = d3.svg.line()
    .interpolate('linear')
    .x(function(d) {
      return x(d.dataIndex);
    })
    .y(function() {
      return y(staticRate);
    });

  var barPriceLine = d3.svg.line()
    .interpolate('cardinal')
    .x(function(d) {
      return x(d.dataIndex);
    })
    .y(function(d) {
      return y(parseInt(d.lowestOther.amountBeforeTax, 10));
    });

  var dynamicPriceLine = d3.svg.line()
    .interpolate('cardinal')
    .x(function(d) {
      return x(d.dataIndex);
    })
    .y(function(d) {
      return y(calculateDcpDiscount(d.lowestOther));
    });

  // Draw static price
  drawStaticValue(svg, width, y(staticRate));

  // Draw lines
  lines.forEach(function(line) {
    svg.append('path')
      .datum(line)
      .attr('class', 'line barline')
      .attr('d', barPriceLine);

    svg.append('path')
      .datum(line)
      .attr('class', 'line dynamicline')
      .attr('d', dynamicPriceLine);
  });

  staticLines.forEach(function(staticLine) {
    svg.append('path')
      .datum(staticLine)
      .attr('class', 'line')
      .attr('d', staticPriceLine);
  });

  $('body').removeClass('loading');
}
