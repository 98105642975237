function generateNoAvailabilityBars(svg, graphWidth, graphHeight, data, x) {
  var availability = svg.append('g')
    .attr('class', 'availability');

  var barIndexes = [];
  data.forEach(function(day, index) {
    if(!day.roomAvailable) {
      // There will be no line leading from previous day to a day with no availability
      // so provided the previous index isn't off the graph (< zero) we'll add it.
      var previousIndex = index - 1;
      if(previousIndex >= 0 && barIndexes.indexOf(previousIndex) === -1) {
        barIndexes.push(previousIndex);
      }
      // Current day is missing so add that as well unless it is already in the array.
      // This will happen if there are consecutive days with no availability.
      if(barIndexes.indexOf(index) === -1) {
        barIndexes.push(index);
      }
    }
  });

  // Chunks data into groups of bars e.g. [[0, 1, 2], [14, 15, 16, 17]]
  var barGroups = [];
  var buffer = [];
  barIndexes.forEach(function(bar, index) {
    // First item always goes into buffer
    if(index === 0) {
      return buffer.push(bar);
    }
    // If current number is one greater than the last add it to the buffer
    if(bar - 1 === barIndexes[index - 1]) {
      return buffer.push(bar);
    }
    // Otherwise empty the buffer into barGroups and create new buffer
    barGroups.push(buffer);
    buffer = [
      bar
    ];
  });
  // Make sure the buffer gets cleared
  if(buffer.length) {
    barGroups.push(buffer);
    buffer = [];
  }

  // Draw bars on graph
  barGroups.forEach(function(barGroup) {
    var startPoint = x(barGroup[0]);
    var endPoint = x(barGroup[barGroup.length - 1] + 1);
    availability.append('rect')
      .attr('class', 'no-availability-bar')
      .attr('width', endPoint - startPoint)
      .attr('height', graphHeight)
      .attr('x', startPoint)
      .attr('y', 0);
  });

}
